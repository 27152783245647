import { deprecated } from "typesafe-actions";

const createAction = deprecated.createAction;

export const proposeAvailableChip = createAction(
  "PROPOSE_AVAILABLE_CHIP",
  (resolve) => (chipName: string) => resolve(chipName)
);

export const cancelProposedChip = createAction(
  "CANCEL_PROPOSED_CHIP",
  (resolve) => (chipName: string) => resolve(chipName)
);

export const cancelActiveChip = createAction(
  "CANCEL_ACTIVE_CHIP",
  (resolve) => (chipName: string) => resolve(chipName)
);

export const restoreCancelledChip = createAction(
  "RESTORE_CANCELLED_CHIP",
  (resolve) => (chipName: string) => resolve(chipName)
);

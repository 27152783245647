import { startOfDay } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { isEqual } from "lodash";
import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "../";
import { getNextEvent } from "../events/reducers";
import { getTeamsById } from "../teams/reducers";
import * as actions from "./actions";
import {
  FixturesByEvent,
  IFixture,
  IFixtureGroup,
  IFixturesForEventByTeam,
  IGroupedFixturesByEvent,
  IState,
} from "./types";

// State Reducer
export default combineReducers<IState, RootAction>({
  byEvent: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.addFixturesForEvent.success):
        return {
          ...state,
          [action.payload.event]: action.payload.data,
        };
      case getType(actions.addFixtures.success): {
        const eventSlice: FixturesByEvent = {};
        action.payload.forEach((f) => {
          if (f.event) {
            if (!eventSlice[f.event]) {
              eventSlice[f.event] = [];
            }
            eventSlice[f.event].push(f);
          }
        });
        return {
          ...state,
          ...eventSlice,
        };
      }
      default:
        return state;
    }
  },
  allStatsById: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.addAllStatsForFixture.success):
        return {
          ...state,
          [action.payload.fixture]: action.payload.data,
        };
      default:
        return state;
    }
  },
});

// State Selectors
export const getFixturesByEvent = (state: RootState) => state.fixtures.byEvent;

export const getGroupedFixturesByEvent = (state: RootState) => {
  const fixturesByEvent = getFixturesByEvent(state);
  const groupedFixturesByEvent: IGroupedFixturesByEvent = {};

  Object.keys(fixturesByEvent).forEach((eventId) => {
    const fixtures = fixturesByEvent[eventId];
    const groups: IFixtureGroup[] = [];
    let currentGroup: IFixtureGroup | null = null;
    fixtures.forEach((fixture: IFixture) => {
      if (fixture.kickoff_time) {
        const kickoffDate = new Date(fixture.kickoff_time);
        const fixtureDate = startOfDay(
          utcToZonedTime(
            kickoffDate,
            Intl.DateTimeFormat().resolvedOptions().timeZone
          )
        );
        if (currentGroup == null || !isEqual(fixtureDate, currentGroup.date)) {
          currentGroup = {
            date: fixtureDate,
            fixtures: [],
          };
          groups.push(currentGroup);
        }
        currentGroup.fixtures.push({ kickoffDate, ...fixture });
      }
    });
    groupedFixturesByEvent[eventId] = groups;
  });
  return groupedFixturesByEvent;
};

export const getFixturesForEvent = (state: RootState, eventId: number) =>
  state.fixtures.byEvent[eventId] || [];

export const getFixturesForEventById = (state: RootState, eventId: number) => {
  const fixtures = getFixturesForEvent(state, eventId);
  if (!fixtures.length) {
    return null;
  }
  return fixtures.reduce((memo, f) => ({ ...memo, [f.id]: f }), {});
};

export const getFixturesForEventByTeam = (
  state: RootState,
  eventId: number
) => {
  const data: IFixturesForEventByTeam = Object.keys(getTeamsById(state)).reduce(
    (memo, id) => ({
      ...memo,
      [id]: [],
    }),
    {}
  );
  getFixturesForEvent(state, eventId).forEach((f) => {
    data[f.team_h].push(f);
    data[f.team_a].push(f);
  });
  return data;
};

export const getFixturesForNextEventByTeam = (state: RootState) => {
  const nextEvent = getNextEvent(state);
  return getFixturesForEventByTeam(state, nextEvent ? nextEvent.id : 0);
};

export const getAllStatsForFixture = (state: RootState, fixtureId: number) =>
  state.fixtures.allStatsById[fixtureId] || null;
